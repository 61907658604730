import React from 'react'
import Navbar from './Navbar'

function Project2() {
  return (
    <div id ="Project2_Page"><div>Project2</div>
    <div className='Bottom_navbar'><Navbar/></div></div>
  )
}

export default Project2